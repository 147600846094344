import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92')
];

export const server_loads = [0,3,5,7,9,10,11,14,15,18,22,23];

export const dictionary = {
		"/": [~25],
		"/agenda": [~26,[2]],
		"/calendar": [~27,[3]],
		"/changelog": [~28,[4]],
		"/dashboard": [~29,[5]],
		"/delete-me": [30],
		"/invitation/[slug]": [~31],
		"/login": [~32],
		"/logout": [~33],
		"/password-reset/[slug]": [~34],
		"/patients": [~35,[6]],
		"/patients/[id]": [~36,[6,7]],
		"/patients/[id]/actions": [~37,[6,7,8]],
		"/patients/[id]/billing": [38,[6,7,9]],
		"/patients/[id]/billing/[iid]": [~39,[6,7,9]],
		"/patients/[id]/chart": [~40,[6,7,10]],
		"/patients/[id]/chart/dentition": [~57,[6,7,10]],
		"/patients/[id]/chart/endodontic": [58,[6,7,10]],
		"/patients/[id]/chart/periodontal": [59,[6,7,10]],
		"/patients/[id]/chart/[tid]": [41,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/caries": [42,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/conditions": [43,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/conditions/[cid]": [44,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/diastema": [45,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/extraction": [46,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/extraction/[eid]": [47,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/impacted": [48,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/missing": [49,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/movements": [50,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/permanent": [~51,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/primary": [52,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/procedures": [53,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/procedures/[pid]": [54,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/restorations": [55,[6,7,10,11]],
		"/patients/[id]/chart/[tid]/restorations/[rid]": [56,[6,7,10,11]],
		"/patients/[id]/general": [~60,[6,7,12]],
		"/patients/[id]/general/appointments": [~61,[6,7,12,13]],
		"/patients/[id]/general/consent2": [62,[6,7,12,14]],
		"/patients/[id]/general/consent2/[cid]": [~63,[6,7,12,14]],
		"/patients/[id]/general/medical-history": [~64,[6,7,12]],
		"/patients/[id]/general/messages": [65,[6,7,12]],
		"/patients/[id]/general/prescription": [~66,[6,7,12,15]],
		"/patients/[id]/general/prescription/new": [~68,[6,7,12,15,16]],
		"/patients/[id]/general/prescription/[pid]": [~67,[6,7,12,15]],
		"/patients/[id]/general/profile": [~69,[6,7,12]],
		"/patients/[id]/timeline": [~70,[6,7]],
		"/patients/[id]/whiteboard/[wid]": [~71,[6,7]],
		"/register": [~72],
		"/settings": [~73,[17]],
		"/settings/account": [~74,[17]],
		"/settings/branches": [~75,[17]],
		"/settings/branches/[id]": [~76,[17]],
		"/settings/consent2": [~77,[17,18]],
		"/settings/consent2/new": [~79,[17,18]],
		"/settings/consent2/[id]": [~78,[17,18]],
		"/settings/payouts": [~80,[17,19]],
		"/settings/preferences": [81,[17,20]],
		"/settings/prescription": [~82,[17,21]],
		"/settings/prescription/new": [~84,[17,21]],
		"/settings/prescription/[id]": [~83,[17,21]],
		"/settings/price-list": [85,[17,22]],
		"/settings/price-list/[id]": [~86,[17,22]],
		"/settings/profile": [~87,[17]],
		"/settings/subscription": [~88,[17,23]],
		"/settings/subscription/change": [~89,[17,23]],
		"/settings/subscription/purchases": [~90,[17,23]],
		"/settings/subscription/sms": [~91,[17,23]],
		"/settings/users": [~92,[17,24]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';